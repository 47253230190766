* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

html {
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $dark;
    background: $body;
    line-height: 20px;
  }
}

iframe {
  display: none !important;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p {
  font-family: $font-medium;
  width: 100%;
  margin: 0 auto;
  display: block;
  color: $dark;
  line-height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-semi-bold;
  font-weight: $font-weight-bolder;
}

.card {
  background-color: var(--color-white);
  border-radius: 26px;
  border: none !important;
  box-shadow: none !important;
}

.card-bordered {
  background-color: var(--color-white) !important;
  box-shadow: 11px 0px 73px rgba($black, 0.08) !important;
  border: 8px;
}

.InovuaReactDataGrid,
.InovuaReactDataGrid__header,
.InovuaReactDataGrid__column-header
{
  border: none !important;
}

.InovuaReactDataGrid__column-header {
  padding: 4px;
  border: none !important;
  background: $grid-header-color-bg;
}

.InovuaReactDataGrid__column-header__content {
  font-size: 14px;
  font-weight: 500 !important;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  color: $grid-header-color;
}

.InovuaReactDataGrid__column-header--show-border-right {
  border-right: none !important;
}

.InovuaReactDataGrid__column-header--show-border-left, .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
  border-left: none !important;
}

.InovuaReactDataGrid__cell{
  padding: 0 8px !important;
}

.form-label {
  color: var(--color-black);
  font-weight: $font-weight-bold;
}

.bs-stepper-box{
  border-radius: 50% !important;
}

.modal-content {
  border-radius: 26px !important;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.z-index-100 {
  z-index: 100;
}

.text-help {
  color: var(--color-text-help);
}

.inputAddRemove:focus + .text-help {
  color: var(--color-text-help-active) !important;
}

.text-help-active {
  color: var(--color-text-help-active) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.main-container{
  margin-left: 40px;
  min-height: calc(100vh - 90px);
}

.fs-9 {
  font-size: 9px !important;
}