.gradient-bg {
  background: var(--color-gradient) !important;
  color: var(--color-white) !important;
}

.gradient-inverse-bg {
  background: var(--color-gradient-inverse) !important;
  color: var(--color-white) !important;
}

.navbar-container {
  position: absolute;
  bottom: 45%;
  left: 86% !important;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.nav-item {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bell {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.nav-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  display: grid;
  margin-bottom: 10px;
}

.nav-director {
  font-size: 1.25rem;
  margin-left: 10px;
  display: grid;
  margin-bottom: 10px;
}

.bold {
  font-family: var(--font-primary-bold);
}

$border_radius: 20px;
$border_radius_1: 200px;

$shadow_1: 0px 17px 13px 0px #192c4b05;
$shadow_2: 0px 1px 2px rgba(0, 0, 0, 0.05);
$shadow_3: 0 33px 88px 0 #1d0e6211;

@mixin text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

$html-font-size: 16px;

.ellipsis {
  @include text-ellipsis();
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.text-tertiary-dark {
  color: var(--color-tertiary-dark) !important;
}