/* Bootstrap colors override */
$primary:       #F0FAFF;
$secondary:     #F6F4FF;
$tertiary:      #FAE9F1;
$primaryDark:   #3072F9;
$primaryDark2:  #6366F1;
$secondaryDark: #604BBA;
$tertiaryDark:  #E83E8C;
$success:       #28c76f;
$info:          #1691EB;
$warning:       #FC9106;
$danger:        #EB5050;
$light:         #F4F3F3;
$dark:          #1A1D1E;
$red:           red;
$white:         #ffffff;
$black:         #000000;
$black-light:   #343a40;
$gray:          #D1D8D9;
$grayDark:      #5e5873;
$grayLight:     #555e68;

/* Theme extra colors */
$body:          #F5F7FE;
$bodyDark:      #868E96;
$carbon:        #969696;
$pearl:           #D7DBD9;
$lunar-gray:    #758487;
$ultramarin:    #064BFC;
$events:        #E5F6FF;
$sky:           transparent;
$aqua-hover:    #A8ECFF;
$aqua-secondary:#E7F8FD;
$pearl-hover:   #DADADA;
$primary-hover: #42F2D2;
$primary-light: #E7FDF9;
$fuchsia:       #B906FC;
$hero:          #A7E9DD;
$eventCardGradient: linear-gradient(90deg, rgba(249,226,237,1) 0%, rgba(241,247,253,1) 77%);
$grand: transparent linear-gradient(90deg,#e83e8c,#5680e9);
$grand-inverse: transparent linear-gradient(90deg,#5680e9, #e83e8c);
$eventCard: rgb(249,226,237);
$shadow: rgba(0, 0, 0, .10196078431372549 );
$default: rgba(4, 12, 33, 0.45);
$add: #B6E7FD;
$muted:          #b9b9c3;


/* Buttons */
$button-first-color: #e83e8c;
$button-second-color: #5680e9;
$button-third-color: #e6e6e6;

/* Tag Colors */
$tag-border: #DDD;
$tag-background: #EEE;

/* Css variables */
:root {
  --bg-circle-1:            rgba(42, 163, 255, 0.11);
  --bg-instructor-circle-1: rgba(42, 163, 255, 0.11);
  --bg-distributor-circle-1:rgba(92, 99, 255, 0.12);
  --bg-circle-2:            rgba(232, 62, 140, 0.05);
  --bg-instructor-circle-2: rgba(232, 62, 140, 0.05);
  --bg-distributor-circle-2:rgba(220, 54, 207, 0.05);
  --bg-success:             rgba(40, 199, 111, 0.24);
  --bg-body:                rgba(215, 219, 217, 0.15);
  --bg-body-dark:           rgba(215, 219, 217, 0.06);
  --bg-body-light:          rgba(215, 219, 217, 0.2);
  --bg-calendar-header:     #F5F7FE;
  --bg-event-confirmed:     #E7EAF9;
  --bg-white:               rgba(255, 255, 255, 0.98);
  --bg-black:               rgba(0, 0, 0, 0.98);
  --bg-shadow:              rgba(0, 0, 0, 0.08);
  --bg-gradient:            transparent linear-gradient(90deg,#e83e8c,#5680e9);
  --bg-gradient-inverse:     transparent linear-gradient(90deg,#5680e9,#e83e8c);
  --bg-button-second:        #{$button-second-color};
  --bg-button-add:           #{$add};
  --bg-pearl:                #{$pearl};
  --bg-event-card-gradient:  #{$eventCardGradient};
  --bg-event-card:           #{$eventCard};
  --bg-tags:                 #{$tag-background};
  --border-tags:             #{$tag-border};
  --color-hero:              #{$hero};
  --color-gradient:          #{$grand};
  --color-gradient-inverse:  #{$grand-inverse};
  --color-mint:              #{$primary};
  --color-mint-hover:        #{$primary-hover};
  --color-mint-light:        #{$primary-light};
  --color-aqua:              #{$secondary};
  --color-aqua-hover:        #{$aqua-hover};
  --color-aqua-secondary:    #{$aqua-secondary};
  --color-punch-green:       #{$success};
  --color-ultra-aqua:        #{$info};
  --color-orange:            #{$warning};
  --color-salmon:            #{$danger};
  --color-red:               #{$red};
  --color-pearl:             #{$pearl};
  --color-pearl-hover:       #{$pearl-hover};
  --color-background-2:      #{$light};
  --color-primary-dark:      #{$primaryDark};
  --color-primary-dark-2:      #{$primaryDark2};
  --color-secondary-dark:    #{$secondaryDark};
  --color-tertiary:          #{$tertiary};
  --color-tertiary-dark:     #{$tertiaryDark};
  --color-black-light:       #{$black-light};
  --color-night:             #{$dark};
  --color-white:             #{$white};
  --color-black:             #{$black};
  --color-info:              #{$info};
  --color-carbon:            #{$carbon};
  --color-lunar-gray:        #{$lunar-gray};
  --color-ultramarin:        #{$ultramarin};
  --color-body:              #{$body};
  --color-body-dark:         #{$bodyDark};
  --color-sky:               #{$sky};
  --color-gray:              #{$gray};
  --color-fuchsia:           #{$fuchsia};
  --color-shadow:            #{$shadow};
  --color-button-first:      #{$button-first-color};
  --color-button-second:     #{$button-second-color};
  --color-button-third:      #{$button-third-color};
  --color-gray-dark:         #{$grayDark};
  --color-gray-light:        #{$grayLight};
  --color-text-help:        #{$muted};
  --color-text-help-active:     #{$button-second-color};
  --font-primary:            'Poppins';
  --font-medium:             'Poppins';
  --font-semi-bold:          'Poppins';
  --font-bold:               'Poppins';
  --font-title:              'Poppins';
}

$theme-colors: (
  "primary":     $primary,
  "secondary":   $secondary,
  "tertiary":    $tertiary,
  "primary-dark": $primaryDark,
  "secondary-dark": $secondaryDark,
  "tertiary-dark": $tertiaryDark,
  "success":     $success,
  "info":        $info,
  "warning":     $warning,
  "body":        $body,
  "body-dark":   $bodyDark,
  "danger":      $danger,
  "light":       $light,
  "dark":        $dark,
  "white":       $white,
  "mint":        $primary,
  "button-second": $button-second-color,
  "button-third": $button-third-color,
  "aqua":        $secondary,
  "aqua-hover":  $aqua-hover,
  "ultra-aqua":  $info,
  "orange":      $warning,
  "salmon":      $danger,
  "pearl":       $white,
  "pearl-hover": $pearl-hover,
  "background-2":$light,
  "events":      $events,
  "black-light": $black-light,
  "gray-light":   $grayLight,
  "night":       $dark,
  "carbon":      $carbon,
  "red":        $red,
  "lunar-gray":  $lunar-gray,
  "ultramarin":  $ultramarin,
  "sky":         $sky,
  "mint-hover":  $primary-hover,
  "fuchsia":     $fuchsia,
  "default":     $default,
);

/* Classes are generated from the colors */
@each $name, $color in $theme-colors {
  .#{'' + $name}-bg {
    background: $color;
  }
  .#{'' + $name} {
    color: $color;
  }
}
