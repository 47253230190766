/*  Theme Typography - font families */
$font-primary: "Poppins";
$font-medium: "Poppins";
$font-semi-bold: "Poppins";
$font-bold: "Poppins";
$font-title: "Poppins";

$font_main: $font-medium, -apple-system, sans-serif, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font_code: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font_pre: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font_titles: $font-medium, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Bootstrap override base font-family */
$font-family-sans-serif: $font-medium;

/* Theme Typography - font sizes */
$font_size_xsmall: 12px;
$font_size_small: 15px;
$font_size_regular: 16px;
$font_size_large: 22px;
$font_size_large_mobile: 16.6px;

$font_size_h1: 55px;
$font_size_h1_mobile: 40px;

$font_size_h2: 34px;
$font_size_h2_mobile: 32px;

$font_size_h3: 26px;
$font_size_h3_mobile: 24px;

$font_size_h4: 20px;
$font_size_h4_mobile: 19px;

$font_size_h5: 18px;
$font_size_h5_mobile: 16px;

$font_size_h6: 16px;
$font_size_h6_mobile: 15.5px;

$font_lh: 1.65;
