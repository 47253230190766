// Modals

.custom-modal-style {
  width: 897px;
  height: 802px;
  }
  
// Modal XS
.modal-xs {
  max-width: 300px;
}

// Slide In Modal //
.modal-slide-in,
.modal-slide-in .modal {
  padding: 0 !important;
  overflow: hidden !important;
}

.modal-slide-in {
  .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    margin: 0;
    max-width: none;
    width: ($modal-slide-in-width-sm - 5);

    .modal-content {
      padding-bottom: ($modal-title-line-height * 1rem) + $modal-header-padding-y;
      padding-top: ($modal-title-line-height * 1rem) + $modal-header-padding-y;
      overflow: auto;
      border-radius: 0;
      height: 100%;
    }

    .modal-body {
      padding-bottom: 0;
      padding-top: 0;
      margin: auto 0;
      flex-grow: 0;
    }

    @media (min-width: (map-get($grid-breakpoints, sm))) {
      width: $modal-slide-in-width;
    }

    &.sidebar-sm {
      width: $modal-slide-in-width-sm;
    }
    &.sidebar-lg {
      @media (min-width: (map-get($grid-breakpoints, sm))) {
        width: $modal-slide-in-width-lg;
      }
    }
  }

  .close {
    top: $modal-header-padding-y / 2;
    z-index: 10;
    transform: none;
    position: absolute;
    top: 1.5rem;
    right: $modal-header-padding-x;
    margin: 0;
    padding: 0 !important;
    line-height: 0.5;
    transform: translate(0, -50%);
  }
}

// Slide from Right
.modal-slide-in.fade .modal-dialog,
.modal-slide-in .modal.fade .modal-dialog {
  transform: translateX(100%);
  transition: transform 0.1s ease-out;
}

.modal-slide-in.show .modal-dialog,
.modal-slide-in .modal.show .modal-dialog {
  transform: translateX(0) !important;
  transition: transform 0.1s ease-out;
}

// To remove Max-width of XS Modal in Small Screen

@media (max-width: 576px) {
  .modal {
    padding-right: 1rem;
    padding-left: 1rem;
    .modal-xs,
    .modal-sm {
      max-width: unset;
    }
    &.modal-sticky .modal-dialog {
      margin-right: 0.2rem !important;
      margin-left: 0.2rem;
      margin-bottom: 3rem;
    }
  }
}
