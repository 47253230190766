.btn-colored {
  -webkit-box-shadow: 0 12px 29px --color-shadow;
  background: transparent -moz-linear-gradient(left,var(--color-button-first) 0,var(--color-button-second 100%));
  background: transparent -webkit-gradient(linear,left top,right top,from(var(--color-button-first)),to(var(--color-button-second)));
  background: transparent -webkit-linear-gradient(left,var(--color-button-first),var(--color-button-second));
  background: transparent linear-gradient(90deg,var(--color-button-first),var(--color-button-second));
  border-radius: 25px;
  border: none;
  box-shadow: 0 12px 29px --color-shadow;
  color: var(--color-white) !important;
  font-size: 14px;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 21px;
  padding: 12px 38px !important;
  text-transform: none !important;
}
