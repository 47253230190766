//Body
$body-color: #6e6b7b;
$border-color: #ebe9f1;

// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-size-base: 1rem;
$font-size-base: 1rem;
$font-size-lg: ceil($font-size-base * 1.25);
$font-size-sm: ceil($font-size-base * 0.85);

$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$line-height-base: 1.45;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.714;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.286;
$h5-font-size: $font-size-base * 1.07;

$headings-font-family: inherit;
$headings-color: black;

$display1-weight: $font-weight-normal;
$display2-weight: $font-weight-normal;
$display3-weight: $font-weight-normal;
$display4-weight: $font-weight-normal;

$lead-font-size: $font-size-base * 1.14;
$lead-font-weight: $font-weight-normal;

$small-font-size: 0.857rem;

$text-muted: #b9b9c3;
$hr-border-color: $border-color;

$blockquote-small-color: $text-muted;

// Animation & transitions
$transition_function: cubic-bezier(0.4, 0, 0.2, 1);

// Avatar
$avatar-size: 32px;
$avatar-status-size: 11px;
$avatar-status-size-lg: 17px;

$avatar-size-xl: 70px;
$avatar-size-lg: 50px;
$avatar-size-sm: 24px;

$border-radius: 0.357rem;
$border-radius-lg: 0.6rem;
$border-radius-sm: 0.25rem;

$avatar-bg: #c3c3c3;
$avatar-group-border: #fff;
$black: #000;
$danger: #f55145;
$info: #03a9f3;
$secondary: #868e96;
$success: #00c292;
$warning: #ffa21a;
$white : #fff;

//Box shadow
$box-shadow: 0 4px 24px 0 rgba($black, 0.1);

//Switchs 
$switch-bg-color: #e2e2e2;
$switch-indicator-color: #e2e2e2;

//forms
$input-border-radius: .25rem;

// $input-focus-color: #5680e9;
$input-focus-border-color: #5680e9;

//editor
$border-color: var(--color-gray);

//navs 
$nav-component-border-color: #ddd;
$nav-pills-link-active-bg: #5680e9;
$nav-link-disabled-color: $text-muted;

//Buttons
$btn-font-size: 16px;
$btn-padding-y: 0.786rem;
$btn-padding-x: 1.5rem;
$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 2rem;

$input-btn-padding-y-sm: 0.486rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-font-size-sm: $font-size-base * 0.9;

$input-btn-padding-y-lg: 0.8rem;
$input-btn-padding-x-lg: 2rem;
$input-btn-font-size-lg: $font-size-base * 1.25;
$btn-line-height: 1;
$btn-border-radius: 0.358rem;
$btn-border-radius-lg: 0.358rem;
$btn-border-radius-sm: 0.358rem;

//Grids 
$grid-header-color-bg: #F5F7FE;
$grid-header-color: #868E96;

// SVG icons
// For Breadcrumb
$double-chevron-right: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevrons-right'%3E%3Cpolyline points='13 17 18 12 13 7'%3E%3C/polyline%3E%3Cpolyline points='6 17 11 12 6 7'%3E%3C/polyline%3E%3C/svg%3E";
// Chevron Icons
$chevron-left: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E";
$chevron-right: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E";
$chevron-up: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E";
$chevron-down: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E";

// Wizard
$header-padding-y: 1.5rem;
$header-padding-x: 1.5rem;
$content-padding-x: 1.5rem;
$content-padding-y: 1.5rem;
$trigger-padding-vertical: 1rem;
$step-box-height: 38px;
$step-box-width: 38px;
$body-color: #6e6b7b;
$gray-100: #babfc7; 
$text-muted: #b9b9c3;
$border-color: #ebe9f1;
$title-control-color: #1E1E1E;

//radio
$custom-control-indicator-checked-bg: #5680e9;

// Timeline
$timeline-border-color: $border-color !default;

$timeline-point-size: 1.75rem !default;
$timeline-point-indicator-size: 12px !default;
$timeline-point-indicator-color: $info !default;
$timeline-point-indicator-wrapper-size: 20px !default;

$timeline-item-min-height: 4rem !default;
$timeline-item-icon-font-size: 0.85rem !default;

$timeline-event-time-size: 0.85rem !default;
$timeline-event-time-color: $text-muted !default;

// Listing requests
$text-help-color: #5680e9;


// Modal
$modal-inner-padding: 0.8rem 1.4rem !default;

$modal-footer-border-color: rgba($black, 0.05) !default;

$modal-header-padding-y: 0.8rem !default;
$modal-header-padding-x: 1.4rem !default;
$modal-content-border-radius: 0.358rem !default;

// $modal-xl: 94% !default;
$modal-sm: 400px !default;

//    Slide In Modal
$modal-slide-in-width-sm: 25rem !default; // custom
$modal-slide-in-width: 28rem !default; // custom
$modal-slide-in-width-lg: 30rem !default; // custom
$content-padding: 2rem !default;

//  -------------------------------
//  Timeline
//  -------------------------------
$timeline-border-color: $border-color !default;

$timeline-point-size: 1.75rem !default;
$timeline-point-indicator-size: 12px !default;
$timeline-point-indicator-color: $primary !default;
$timeline-point-indicator-wrapper-size: 20px !default;

$timeline-item-min-height: 4rem !default;
$timeline-item-icon-font-size: 0.85rem !default;

$timeline-event-time-size: 0.85rem !default;
$timeline-event-time-color: $text-muted !default;
